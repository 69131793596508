<template>
  <v-container fluid>
    <h1>{{ $t("myAccount") }} (TBD)</h1>
    <div>{{ JSON.stringify(authUser) }}</div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Account",

  computed: {
    ...mapGetters({
      authUser: "auth/user",
    }),
  },
};
</script>
